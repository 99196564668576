<template>
  <div lang="de" style="hyphens: auto; word-break: normal">
    <v-app
      v-if="
        !['TarifSwitch', 'Registration', 'UploadMeter', 'LinkBasedMeterUpload'].includes(
          $route.name
        )
      "
      v-show="loggedIn || ['Error', 'Maintenance', 'NoContracts'].includes($route.name)"
      id="inspire">
      <!-- Custom navigation drawer -->
      <NavigationDrawer
        v-if="!['Error', 'Maintenance', 'NoContracts'].includes($route.name)"
        :drawer="drawer" />
      <v-main :class="`${$route.name == 'Referral' ? 'background_light' : 'secondary'} pt-2 pb-10`">
        <v-container class="main-container">
          <!-- Router View -->
          <router-view />
        </v-container>
      </v-main>
      <!-- Custom footer -->
      <FooterComponent />

      <!-- Loader -->
      <v-dialog v-model="loading" persistent width="300">
        <v-card dark color="accent">
          <v-card-text>
            <p class="pt-2 ft-500-dark">
              {{ $vuetify.lang.t('$vuetify.app.loader_text') }}
            </p>
            <v-progress-linear indeterminate color="secondary" class="mb-0"></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Error snackbar -->
      <v-snackbar v-model="errorLocal" :timeout="timeout" color="white">
        <v-row>
          <v-col cols="1">
            <v-icon color="red" large>mdi-alert-circle-outline</v-icon>
          </v-col>
          <v-col>
            <h4 class="black--text" v-html="snackBarText"></h4>
          </v-col>
        </v-row>

        <template #action="{ attrs }">
          <v-btn color="red" text v-bind="attrs" @click="closeSnackbar"> Schließen </v-btn>
        </template>
      </v-snackbar>
    </v-app>
    <v-app
      v-if="['Registration', 'TarifSwitch', 'LinkBasedMeterUpload'].includes($route.name)"
      id="inspire">
      <!-- Router View -->
      <router-view />
    </v-app>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import FooterComponent from './components/FooterComponent'
import NavigationDrawer from './components/NavigationDrawer'

export default {
  data: () => ({
    drawer: false,
    snackbar: false,
    snackBarText:
      'Leider gab es ein Problem beim laden der Daten. Wir arbeiten mit tierischer Geschwindigkeit an einer Lösung. <br>Bitte versuche es später noch einmal.',
    timeout: -1,
  }),
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      error: state => state.error,
      loggedIn: state => state.loggedIn,
    }),
    loading: {
      // getter
      get: function () {
        return this.$store.getters.loading
      },
      // setter
      set: function (newValue) {
        this.$store.commit('set_loading', newValue)
      },
    },
    errorLocal: {
      // getter
      get: function () {
        return this.error
      },
      // setter
      set: function () {},
    },
  },
  methods: {
    closeSnackbar() {
      this.$store.commit('set_error', null)
      this.snackbar = false
    },
  },
  components: {
    FooterComponent,
    NavigationDrawer,
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@500;700&display=swap');

@font-face {
  font-family: 'Manrope';
  src: local('Manrope'), url('/fonts/Manrope-VariableFont_wght.ttf') format('truetype');
}
@font-face {
  font-family: 'Baloo';
  src: local('Baloo'), url('/fonts/Baloo Bhaijaan Regular.ttf') format('truetype');
}

html,
body,
.v-application {
  font-family: 'Manrope', sans-serif;
  color: #242a2a !important;
}
// Custom classes for text
.ft-700 {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: normal !important;
}
.ft-700-14 {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: normal !important;
}

.ft-700-18 {
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: normal !important;
}

.ft-700-24 {
  font-weight: 700 !important;
  font-size: 24px !important;
  line-height: normal !important;
}

.ft-500 {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: normal !important;
  color: #242a2a !important;
}

.ft-500-dark {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: normal !important;
  color: white !important;
}

.ft-500-caption {
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: normal !important;
  color: rgb(0, 0, 0, 0.5) !important;
}

#app {
  font-family: 'Manrope', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #242a2a;
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
}

.font-headline {
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  font-family: 'Baloo' !important;
}
.font-subheadline {
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  font-family: 'Manrope' !important;
}

.v-input,
.v-input .v-label {
  font-size: 14px !important;
}
@media screen and (min-width: 1904px) {
  .main-container {
    padding: 0 200px !important;
  }
}
</style>
