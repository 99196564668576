<template>
  <div>
    <v-container grid-list-xs>
      <v-row>
        <v-col>
          <center>
            <h1 style="font-family: Baloo">
              {{ $vuetify.lang.t('$vuetify.no_contracts_page.info') }}
            </h1>
          </center>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <center>
            <v-btn height="45" color="accent" class="text-none ft-700" @click="reset">
              {{ $vuetify.lang.t('$vuetify.no_contracts_page.logout') }}
            </v-btn>
          </center>
        </v-col>
      </v-row>
    </v-container>
    <p></p>
  </div>
</template>

<script>
export default {
  methods: {
    reset() {
      this.$store.commit('logout')
    },
  },
}
</script>

<style></style>
