import { de } from 'vuetify/es5/locale'

export default {
  ...de,

  app: {
    loader_text: 'Daten werden geladen, bitte warten ...',
  },
  callback: {
    loading_text: 'Anfrage wird geladen ...',
  },
  // Views
  dashboard: {
    header_location: 'Übersicht',

    widget_contact_call_text:
      'Vereinbare einen Rückruftermin. Wir rufen dich schnellstmöglich zurück.',

    widget_simple_1_title: 'CO2 Fußabdruck berechnen',
    widget_simple_1_content:
      'Kennst Du schon Deinen persönlichen CO2-Fußabdruck? Berechne ihn jetzt!​',
    widget_simple_1_icon: 'calculate',

    widget_simple_2_title: 'CO2 kompensieren',
    widget_simple_2_content: 'TODO',
    widget_simple_2_icon: 'compensate',

    widget_simple_3_title: 'Meine Dokumente',
    widget_simple_3_content: 'Vertragsunterlagen, Rechnungen',
    widget_simple_3_icon: 'dokumente',

    widget_simple_4_title: 'Mein Zählerstand',
    widget_simple_4_content: 'Zählerstandhistorie einsehen',
    widget_simple_4_icon: 'zeahlerstand',

    widget_simple_5_title: 'Meine Daten',
    widget_simple_5_content: 'Rechnungs- und Lieferanschrift',
    widget_simple_5_icon: 'meine-daten',

    widget_simple_6_title: 'Vertragsübersicht',
    widget_simple_6_content: 'Relevante Informationen zum Stromvertrag',
    widget_simple_6_icon: 'dokumente',

    widget_simple_image_1_title: 'Umzug geplant?',
    widget_simple_image_1_content:
      'Hier findest du Informationen, wie du nach Deinem Umzug weiter durch Veganstrom versorgt werden kannst.',
    widget_simple_image_1_image: 'auszug@2x',

    widget_simple_image_2_title: 'Häufige Fragen',
    widget_simple_image_2_content:
      'Wir haben besonders häufig gestellte Fragen für dich gesammelt.',
    widget_simple_image_2_image: 'faq@2x',

    widget_simple_image_3_title: 'Über VeganStrom',
    widget_simple_image_3_content: 'Das sind wir – der tierfreundliche Ökostromanbieter',
    widget_simple_image_3_image: 'solar-panels',
  },
  my_data: {
    header_title: 'Verwalte Deine Benutzerdaten',
    header_location: 'Meine Daten',

    widget_data_contact_title: 'Kontaktdaten',
    widget_data_contact_salutation: 'Anrede',
    widget_data_contact_person_title: 'Titel',
    widget_data_contact_first_name: 'Vorname',
    widget_data_contact_last_name: 'Nachname',
    widget_data_contact_birthday: 'Geburtsdatum',
    widget_data_contact_email: 'E-Mail',
    widget_data_contact_phone: 'Telefon',
    widget_data_contact_date_of_birth: 'Geburtsdatum',
    widget_data_contact_name: 'Name',
    widget_data_contact_district_court: 'Bezirksgericht',
    widget_data_contact_commercial_register_number: 'Handelsregisternummer',
    widget_data_contact_vat_number: 'Umsatzsteuer-Identifikationsnummer',

    widget_data_billing_title: 'Rechnungsadresse',
    widget_data_billing_address_type: 'Typ',
    widget_data_billing_street: 'Strasse',
    widget_data_billing_house_number: 'Hausnummer',
    widget_data_billing_address_addition: 'Adresszusatz',
    widget_data_billing_plz: 'PLZ',
    widget_data_billing_city: 'Ort',
    widget_data_billing_district: 'Bezirk',

    widget_data_supply_address_title: 'Lieferadresse',
    widget_data_supply_address_street: 'Strasse',
    widget_data_supply_address_house_number: 'Hausnummer',
    widget_data_supply_address_address_addition: 'Adresszusatz',
    widget_data_supply_address_plz: 'PLZ',
    widget_data_supply_address_city: 'Ort',
    widget_data_supply_address_district: 'Bezirk',

    widget_data_banking_title: 'Zahlung & Bankdaten',
    widget_data_banking_payment_method_type: 'Zahlungsart',
    widget_data_banking_full_name: 'Kontoinhaber',
    widget_data_banking_iban: 'IBAN',
    widget_data_banking_bic: 'BIC',
    widget_data_banking_bank_name: 'Bank',
    widget_data_banking_mandate_reference: 'Mandatsreferenz',
  },
  contract_overview: {
    header_title: 'Vertragsübersicht',
    header_location: 'Vertragsübersicht',

    widget_data_contract_title: 'Vertragsdaten',
    widget_data_contract_contract_number: 'Vertragsnummer',
    widget_data_contract_meter_number: 'Zählernummer',
    widget_data_supply_start: 'Lieferbeginn',
    widget_data_contract_start: 'Beginn  der Vertragsbeziehung',
    widget_data_contract_contractor: 'Vertragsnehmer',

    widget_data_supply_address_title: 'Lieferadresse',
    widget_data_supply_address_street: 'Strasse',
    widget_data_supply_address_house_number: 'Hausnummer',
    widget_data_supply_address_address_addition: 'Adresszusatz',
    widget_data_supply_address_plz: 'PLZ',
    widget_data_supply_address_city: 'Ort',
    widget_data_supply_address_district: 'Bezirk',

    widget_simple_image_1_title: 'Umzug geplant?',
    widget_simple_image_1_content:
      'Hier findest du Informationen, wie du nach Deinem Umzug weiter durch Veganstrom versorgt werden kannst.',
    widget_simple_image_1_image: 'auszug@2x',

    widget_simple_image_2_title: 'Häufige Fragen',
    widget_simple_image_2_content: 'Wir haben besonders häufig gestellte Fragen für dich gesammelt',
    widget_simple_image_2_image: 'faq@2x',

    widget_simple_image_3_title: 'Über VeganStrom',
    widget_simple_image_3_content: 'Das sind wir – der tierfreundliche Ökostromanbieter',
    widget_simple_image_3_image: 'solar-panels',
  },
  tarif_overview: {
    header_title: 'Dein VeganStrom-Tarif',
    header_location: 'Tarifdetails',

    widget_contact_call_text:
      'Hast Du Fragen zum Tarif? Vereinbare einen Rückruftermin. Wir melden uns schnellstmöglich bei Dir.',

    widget_data_tarif_title: 'Ausgewählter Tarif',
    widget_data_tarif_contract_number: 'Vertragsnummer',
    widget_data_tarif_meter_number: 'Zählernummer',
    widget_data_tarif_name: 'Name des aktuellen Tarifs',
    widget_data_tarif_contract_term: 'Vertragslaufzeit',
    widget_data_tarif_contract_start: 'Beginn  der Vertragsbeziehung',
    widget_data_tarif_contract_end: 'Min&shy;dest&shy;vertrags&shy;lauf&shy;zeit',
    widget_data_tarif_price_guarantee_date: 'Ende Preisgarantiezeitraum',
    widget_data_tarif_contract_notice_period: 'Kündigungsfrist',
    widget_data_tarif_contract_notice_period_value: '4 Wochen zum Vertragsende',

    widget_data_deduction_title: 'Abschlag',
    widget_data_deduction_street: 'Strasse',
    widget_data_deduction_consumption: 'Erwarteter Verbrauch pro Jahr',
    widget_data_deduction_base_price: 'Grundpreis pro Monat',
    widget_data_deduction_energy_price: 'Verbrauchspreis pro kWh',
    widget_data_deduction_anticipated_payment: 'Abschlag pro Monat',
    widget_data_deduction_payment_method: 'Zahlweise',
  },
  documents: {
    header_title: 'Alle Dokumente im Überblick',
    header_location: 'Dokumente',

    widget_contact_call_text:
      'Hast Du Fragen zur Rechnung? Vereinbare einen Rückruftermin. Wir melden uns schnellstmöglich bei Dir.',
  },
  metering: {
    header_title: 'Zählerstände',
    header_location: 'Zählerstände',

    widget_contact_call_text:
      'Hast Du Fragen zu den Zählerständen? Vereinbare einen Rückruftermin. Wir melden uns schnellstmöglich bei Dir.',
  },
  referral: {
    header_title: 'Gemeinsam 50 € sparen und Tiere & Umwelt  schützen',
    header_location: 'Freunde werben',

    widget_simple_image_1_title: 'FAQ Freunde werben Freunde',
    widget_simple_image_1_content: 'Hier findest Du Antworten auf die häufigsten Fragen zur Aktion',
    widget_simple_image_1_image: 'freunde-werben-freunde@2x',

    widget_simple_image_2_title: 'Mehr Informationen zu Peta',
    widget_simple_image_2_content: 'Wir kooperieren mit der Tierrechtsorganisation',
    widget_simple_image_2_image: 'peta@2x',

    widget_simple_image_3_title: 'Über Vegan Strom',
    widget_simple_image_3_content: 'Das sind wir – der tierfreundliche Ökostromanbieter',
    widget_simple_image_3_image: 'solar-panels',
  },
  newsletter: {
    header_title: 'Immer informiert bleiben',
    header_location: 'Newsletter',
  },
  help_and_contact: {
    header_title_overview: 'Du brauchst Hilfe?',
    header_title_faq: 'Du brauchst Hilfe?',
    header_title_faq_referral: 'Du hast Fragen zu Freunde-werben-Freunde?',
    header_title_call: 'Rückruf anfordern',
    header_title_email: 'Schreibe uns!',
    header_title_move: 'Hast Du einen Umzug geplant?',

    header_location_base: 'Hilfe und Kontakt',
    header_location_faq: 'FAQ',
    header_location_faq_referral: 'FAQ Freunde-werben-Freunde',
    header_location_call: 'Rückruf anfordern',
    header_location_email: 'Kontaktformular',
    header_location_move: 'Umzug',

    faq_question: 'Deine Fragen wurden nicht beantwortet?',
    faq_referral_question: 'Deine Fragen wurden nicht beantwortet?',

    widget_simple_image_1_title: 'Rückruf anfordern',
    widget_simple_image_1_content:
      'Vereinbare einen Rückruftermin. Wir rufen zu Deinem Wunschtermin zurück.',
    widget_simple_image_1_image: 'rueckruf@2x',

    widget_simple_image_2_title: 'Kontaktformular',
    widget_simple_image_2_content: 'Nutze unser Kontaktformular, um uns per E-Mail zu erreichen.',
    widget_simple_image_2_image: 'kontakt@2x',

    widget_simple_image_3_title: 'Häufige Fragen',
    widget_simple_image_3_content: 'Wir haben besonders häufig gestellte Fragen für dich gesammelt',
    widget_simple_image_3_image: 'faq@2x',

    widget_simple_image_4_title: 'Über Vegan Strom',
    widget_simple_image_4_content: 'Das sind wir – der tierfreundliche Ökostromanbieter',
    widget_simple_image_4_image: 'solar-panels',

    widget_data_post_title: 'Kontakt per Post',
    widget_data_post_content: `Bei Fragen kannst Du dich jederzeit an das Team von VeganStrom wenden. Schriftlich erreichst Du uns hier:<br/><br/>
                                <b>GreenStone Energy GmbH</b><br/>
                                z. Hd. Abteilung VeganStrom<br/>
                                Manteuffelstrasse 77<br/>
                                10999 Berlin, Deutschland`,

    widget_data_email_title: 'Kontakt per E-Mail',
    widget_data_email_content: `Am einfachsten kontaktierst Du uns per Mail über 
                                <a href="mailto:service@veganstrom.com">service@veganstrom.com</a>. 
                                Wir werden Dein Anliegen so schnell wie möglich bearbeiten und uns bei Dir zurückmelden.`,
  },
  co2_calculator: {
    header_title: 'Hier kannst Du Deine CO<sub>2</sub>-Bilanz berechnen.',
    header_location: 'CO2 Rechner',
  },
  upload_meter_reading: {
    header_title: 'Zählerstand hochladen',
    header_location: 'Zählerstand hochladen',
  },
  sepa_mandate: {
    header_title: 'SEPA-Lastschriftmandat erteilen',
    header_location: 'SEPA-Lastschriftmandat erteilen',
  },
  registration: {
    title: 'Hallo',
    text: 'um den Anmeldeprozess bei VeganStrom abschließen zu können, benötigen wir noch Deine E-Mail Adresse. Bitte bestätige uns diese im nachfolgenden Formular.',
    email: 'E-Mail',
    email_confirm: 'E-Mail erneut eingeben',
    button_text_send: 'Anmeldung abschließen',
    text_required: 'Dies ist ein Pflichtfeld',
    text_email_same: 'E-Mail stimmt nicht überein',
    alert_success: 'Du erhältst in Kürze Deine Vertragsunterlagen!',
    alert_error:
      'Leider ist ein Fehler aufgetreten oder du hast uns Deine E-Mail bereits gesendet.',
  },
  // Components
  navigation_drawer: {
    menu: 'Menü',
    drawer_item_dashboard: 'Übersicht',
    drawer_item_my_data: 'Meine Daten',
    drawer_item_contract_overview: 'Vertragsübersicht',
    drawer_item_tarif_overview: 'Tarifdetails',
    drawer_item_documents: 'Dokumente',
    drawer_item_metering: 'Zählerstände',
    drawer_item_referral: 'Freunde werben',
    drawer_item_newsletter: 'Newsletter',
    drawer_item_help_and_contact: 'Hilfe & Kontakt',
    drawer_item_sepa: 'SEPA-Mandat',
    drawer_item_my_co2_check: 'CO2 Rechner​',
  },
  header: {
    settings: 'Einstellungen',
    logout: 'Logout',
    hello: 'Hallo',
    customer: 'Kunde',
    contract: 'Vertrag',
    contract_selected_dialog_text_customer: 'Kunde wurde ausgewählt!',
    contract_selected_dialog_text_contract: 'Vertrag wurde ausgewählt!',
    contract_selected_dialog_close: 'Schließen',
  },
  footer: {
    link_vegan_strom: 'VeganStrom',
    link_imprint: 'Impressum',
    link_agb: 'AGB',
    link_data_policy: 'Datenschutz',
  },
  error_page: {
    reload: 'Erneut versuchen',
  },
  no_contracts_page: {
    logout: 'Ausloggen',
    info: 'Du hast leider keinen validen Vertrag bei VeganStrom',
  },
  // Widgets
  widget_contact: {
    title: 'Hilfe & Kontakt',
    text_contact: `Schreib uns eine Mail an
                        <a
                            href="mailto:service@veganstrom.com"
                            class="primary--text text-decoration-none"
                            >service@veganstrom.com
                        </a>
                        oder nutze unser Kontaktformular.`,
    button_text_contact: 'Zum Kontaktformular',
    button_text_call: 'Rückruf anfordern',
  },
  widget_contract: {
    title: 'Vertragsübersicht',
    customer_number: 'Kundennummer',
    meter_number: 'Zählernummer',
    start: 'Beginn',
    contractor: 'Vertragsnehmer',
  },
  widget_tarif: {
    tarif: 'Tarif',
    customer_number: 'Kundennummer',
    contract_term: 'Min&shy;dest&shy;ver&shy;trags&shy;lauf&shy;zeit',
    anticipated_payment: 'Abschlag pro Monat',
  },
  widget_callback: {
    title:
      'Gib einfach Dein Wunschdatum und Deine Telefonnummer an und wir melden uns zwischen 9 und 17 Uhr bei Dir.',
    radio_woman: 'Frau',
    radio_man: 'Herr',
    radio_other: 'Divers',
    first_name: 'Vorname',
    last_name: 'Nachname',
    phone: 'Telefon',
    mobile: 'Mobil',
    preferred_date: 'Wunschdatum',
    preferred_time: 'Wunschzeit',
    button_text_callback: 'Rückruf anfordern',
    button_text_reset: 'Felder zurücksetzen',
    text_required: 'Dies ist ein Pflichtfeld',
  },
  widget_data: {
    popup: 'Bitte nutze für Änderungswünsche unser Kontaktformular',
  },
  widget_email: {
    title: 'Bei Fragen kannst Du dich jederzeit an uns wenden.',
    radio_woman: 'Frau',
    radio_man: 'Herr',
    radio_other: 'Divers',
    first_name: 'Vorname',
    last_name: 'Nachname',
    email: 'E-Mail',
    customer_number: 'Kundennummer',
    subject: 'Betreff',
    message: 'Nachricht',
    button_text_send: 'Absenden',
    button_text_reset: 'Felder zurücksetzen',
    text_required: 'Dies ist ein Pflichtfeld',
    syntax: 'Das ist keine gültige E-Mail.',
    text_max_letters: 'Nachricht darf maximal 300 Zeichen haben.',
    placeholder_subject: 'Meine Frage an VeganStrom',
    alert_success: 'Anfrage erfolgreich verschickt!',
    alert_error: 'Es gab ein Problem beim verschicken der Nachricht.',
  },
  widget_move: {
    title:
      "Möchtest Du Deinen Umzug anmelden, dann teile und bitte Deine <span class='primary--text mx-2'>neue</span> Adresse mit:",
    first_name: 'Vorname',
    last_name: 'Nachname',
    street: 'Strasse',
    house_number: 'Hausnummer',
    zip_code: 'Postleitzahl',
    city: 'Wohnort',
    move_out_date: 'Auszugsdatum',
    move_in_date: 'Einzugsdatum',
    meter_number: 'Zählernummer',
    contact_selection: 'Bevorzugte Kontaktart',
    phone: 'Telefon',
    email: 'E-Mail',
    button_text_send: 'Absenden',
    button_text_reset: 'Felder zurücksetzen',
    text_required: 'Dies ist ein Pflichtfeld',
    syntax: 'Das ist keine gültige E-Mail.',
    text_max_letters: 'Nachricht darf maximal 300 Zeichen haben.',
    alert_success: 'Anfrage erfolgreich verschickt!',
    alert_error: 'Es gab ein Problem beim verschicken der Nachricht.',
  },
  widget_faq: {
    title:
      'Die häufigsten Fragen haben wir für Dich hier gesammelt und geben gleich die Antworten.',
    question_1: 'Was ist das Besondere an Vegan Strom?',
    answer_1: `
    Wie viele Ökostromanbieter haben wir von Vegan Strom das Ziel, den Ausbau Erneuerbarer Energien gezielt voranzutreiben. Denn in Zeiten des menschengemachten Klimawandels liegt es in unserer Hand, umweltbewusstes Handeln aktiv zu fördern. Die übermäßige Nutzung fossiler Energiequellen hat seit dem Beginn der Industriellen Revolution zu immer größer werdenden CO2-Emissionen und einem deutlichen Temperaturanstieg geführt. Im Bereich des Atomstroms ist die Frage der Endlagerung trotz vieler Bemühungen immer noch nicht geklärt – von der Gefahren während der Produktion ganz zu schweigen. Diese umweltschädigende Entwicklung können wir nur eindämmen, indem wir in allen Lebensbereichen – inklusive der Energie – verantwortungsvoll und damit nachhaltig mit den uns zur Verfügung stehenden Ressourcen umgehen.
    <br/><br/>
    Im Gegensatz zu anderen Anbietern von Ökostrom denken wir von Vegan Strom aber noch einen Schritt weiter. Denn neben Nachhaltigkeit ist für uns auch das Wohl von Tieren von größter Bedeutung. Wir finden: Kein Mensch hat das Recht, sich über andere Lebewesen zu stellen. Aus diesem Grund achten wir auch darauf, dass unser Strom tierfreundlich, also vegan produziert wird. Dazu nutzen wir Geothermie und Sonnenenergie, die in Photovoltaikanlagen gewonnen wird. Diese arbeiten emissionsfrei, verschrecken empfindliche Arten nicht durch Geräusche und stellen auch keine Todesfallen für fliegende Tiere dar, wie es zum Beispiel bei Windenergieanlagen der Fall ist. Darüber hinaus dienen Solarparks manchen bedrohten Arten als Rückzugsraum. Auf diese Weise gelingt es uns von Vegan Strom, nachhaltige und umweltfreundliche Energie zu produzieren und gleichzeitig Tiere zu schützen.`,
    question_2: 'Welche Stromtarife gibt es bei Vegan Strom?',
    answer_2: `
    Bei Vegan Strom hast Du als Privatkunde die Wahl zwischen drei verschiedenen Stromtarifen. Bei allen drei Tarifen kannst Du Dich darauf verlassen, dass Du mit veganem Strom aus tierfreundlicher Erzeugung/ mit Ökostrom, der Tiere schützt beliefert wirst. Unterschiede gibt es bei der Mindestvertragslaufzeit, der Preisgarantie und dem monatlichen Preis.
    <br/><br/>
    Am günstigsten für Dich ist unser Tarif Vegan Strom clever und der günstige Preis ist auch noch für 24 Monate garantiert. Bei diesem Tarif beträgt die Mindestvertragslaufzeit 24 Monate.
    <br/><br/>
    Der Tarif mit der kürzesten Laufzeit ist unser Tarif Basic. Die Laufzeit liegt bei drei Monaten und die Preisgarantie entspricht ebenfalls drei Monaten. Der Preis ist hier geringfügig höher.
    <br/><br/>
    Einen attraktiven Kompromiss aus beidem stellt unser Tarif VeganStrom Classic dar. Preislich liegt er in der Mitte. Hier ist der Preis über einen Zeitraum von 12 Monaten garantiert. Die Mindestlaufzeit beträgt ebenfalls 12 Monate.
    <br/><br/>
    Am wichtigsten ist aber: Ganz gleich für welchen der Stromtarife von Vegan Strom Du Dich für Deinen Hausstrom entscheidest – Tierwohl und Umweltschutz stehen garantiert an vorderster Stelle!
    <br/><br/>
    Für Gewerbestrom stehen gesonderte Tarife zur Verfügung.
    `,
    question_3: 'Wie funktioniert die Online-Bestellung bei Vegan Strom?',
    answer_3: `
    Wir freuen uns, dass Du Dich für den tier- und umweltfreundlichen Strom von Vegan Strom entschieden hast. Mit nur wenigen unkomplizierten Schritten kannst Du bei uns einfach eine Online-Anmeldung vornehmen:
    <br/><br/>
    Wähle auf unserer Webseite den für Dich passenden Tarif aus.
    <br/><br/>
    Es öffnet sich ein Bestellformular, in das Du Deine Daten eingibst. Für einen reibungslosen Ablauf wichtig ist hier auch die Übermittlung der Nummer Deines Stromzählers (die Zählernummer findest Du auf dem Gerät oder in Deiner letzten Rechnung).
    <br/><br/>
    Klicke in dem Formular bitte auch an, ob wir im Rahmen der Online-Bestellung für Dich Deinen alten Stromanbieter kündigen sollen.
    <br/><br/>
    Schicke das Formular ab. Dies stellt für uns einen Antrag dar, dessen Eingang bei uns wir Dir mit einer automatischen Mail bestätigen. Diese Mail ist jedoch noch kein Vertragsabschluss.
    <br/><br/>
    Der Vertrag kommt erst zustande, wenn unser Kundendienst Dir per E-Mail eine ausführliche Bestätigung mit den Konditionen zuschickt. Diese beinhaltet auch den Kündigungsnachweis des alten Anbieters und alle vereinbarten Laufzeiten sowie den Lieferbeginn.
    <br/><br/>
    Damit ist die Online-Bestellung abgeschlossen. Wir freuen uns, Dich für unsere Ideen von mehr Tierwohl und Nachhaltigkeit gewonnen zu haben!
    `,
    question_4: 'Wie erfolgt der Stromanbieterwechsel zu Vegan Strom?',
    answer_4: `
    Du möchtest nachhaltigen, veganen Strom beziehen? Kein Problem – das ist ganz leicht! So gehst Du vor: Wähle einen Tarif und melde Dich über das Formular auf der Website von Vegan Strom bei uns an. Anschließend erhältst Du von uns eine Anmeldebestätigung inklusive der Allgemeinen Geschäftsbedingungen (AGB). Um die Kündigung bei Deinem Voranbieter musst Du Dich nicht kümmern, denn das übernehmen wir für Dich. Außerdem melden wir Deine Lieferstelle beim Netzbetreiber an. Anschließend erhalten wir vom Vorversorger eine Kündigungsbestätigung und vom Netzbetreiber eine Anmeldungsbestätigung. Sobald das passiert ist, senden wir Dir ein Begrüßungsschreiben mit allen Informationen rund um Deinen neuen Stromvertrag bei Vegan Strom  – fertig!
    <br/><br/>
    Hast Du bei Deinem vorherigen Anbieter einen Tarif ohne Vertragsbindung, dauert der Wechsel zu Vegan Strom übrigens maximal drei Wochen. Besteht hingegen eine längere Kündigungsfrist oder eine Mindestlaufzeit, sorgen wir dennoch dafür, dass Du so schnell wie möglich veganen, nachhaltigen Strom beziehen kannst und informieren Dich rechtzeitig, ab wann der Vertrag in Kraft tritt.
    `,
    question_5: 'Wie lange dauert der Wechsel des Stromanbieters?',
    answer_5: `Sobald Du Deinen Antrag bei Vegan Strom einreichst, bearbeiten wir diesen und melden Dich beim Netzbetreiber um. Auch die Kündigung Deines alten Vertrages übernehmen wir für Dich, sodass Du Dich im Prinzip um nichts kümmern musst. Wichtig für uns ist vor allem, dass Du alle für uns relevanten Daten vollständig angibst. So vermeiden wir Rückfragen und zeitliche Verschiebungen. Die Dauer des Stromanbieterwechsels ist in erster Linie abhängig von den Kündigungsfristen Deines vorherigen Energieversorgers. Seit April 2012 schreibt das Energiewirtschaftsgesetz einen maximalen Zeitraum von drei Wochen vor. Wenn alles glatt läuft, geht der Wechsel zu Vegan Strom aber häufig schneller über die Bühne.`,
    question_6: 'Soll ich dem bisherigen Stromanbieter selbst kündigen?',
    answer_6: `
    Hast Du Dich für tier- und klimafreundliche Energie von Vegan Strom entschieden, stellt sich die Frage, wie der Stromanbieterwechsel von statten geht. Dabei gibt es zwei Möglichkeiten. Möglichkeit eins: Du kannst den Versorgungsvertrag mit Deinem alten Stromanbieter selbst kündigen. Hierfür können unterschiedliche Fristen gelten, je nachdem, um was für einen Vertrag es sich handelt. Bei der städtischen Grundversorgung sind dies gemäß Grundversorgungsordnung zwei Wochen, bei unabhängigen Anbietern bis zu drei Monaten. Außerdem kommt es darauf an, ob Du einen Vertrag mit Mindestlaufzeit hattest. Die für Dich geltende Kündigungsfrist steht in Deinem bisherigen Stromversorgungvertrag.
    <br/><br/>
    Die andere Möglichkeit ist, die Formalitäten des Wechsels einfach uns zu überlassen: Wir übernehmen für Dich die Kündigung und allen Papierkram, und Du brauchst Dich um nichts weiter zu kümmern.
    <br/><br/>
    <b>Sonderkündigungsrecht: Stromanbieter eventuell selbst kündigen</b>
    <br/><br/>
    Unter bestimmten Umständen steht Strom-Kunden ein Sonderkündigungsrecht zu. Das ist bei einer Preiserhöhung der Fall, unter Umständen aber auch bei einem Umzug. Bei einer Preiserhöhung muss der Anbieter den Kunden sechs Wochen vorher schriftlich informieren, und der Kunde hat dann ein außerordentliches Kündigungsrecht mit einer Frist von zwei Wochen. Da diese Frist recht kurz ist, empfiehlt es sich in diesem Fall, dem alten Stromanbieter sicherheitshalber selbst zu kündigen.
    <br/><br/>
    <b>Stromanbieter selbst kündigen: Wie geht das?</b>
    <br/><br/>
    Für die Kündigung Deines alten Stromanbieters reicht ein formloses Schreiben, in dem jedoch einige Angaben enthalten sein müssen: Name, Anschrift, Kunden- und Stromzählernummer, Kündigungsabsicht und Zeitpunkt (mindestens ein allgemeiner Satz wie: „Hiermit kündige ich meinen bestehenden Stromvertrag zum nächstmöglichen Zeitpunkt.“), Ort, Datum und Unterschrift. Sinnvoll ist eine Bitte um Bestätigung der Kündigung. Besteht ein Sonderkündigungsrecht, solltest Du darauf verweisen und den Grund nennen, wie beispielsweise „Sonderkündigung wegen Preiserhöhung zum TT.MM.JJ“.
    <br/><br/>
    Weißt Du nicht genau, wie Du dem Stromanbieter selbst kündigen sollst, kannst Du Dir eine Formulierungsvorlage aus dem Internet herunterladen. Oder noch einfacher: Überlasse die Kündigung einfach unserem Kundenservice!
    `,
    question_7: 'Wie funktioniert das Stromanmelden bei Einzug in eine neue Wohnung?',
    answer_7: `
    Ziehst Du in eine neue Wohnung und möchtest nachhaltige, tierfreundliche Energie von Vegan Strom beziehen, nutzt Du einfach den Tarifrechner. Im Anschluss wählst Du das passende Tarifpaket und füllst das Anmelde-Formular mit Deinen Daten aus. Folgende Angaben sind für uns wichtig:
    <br/><br/>
    Name,
    <br/><br/>
    Geburtsdatum,
    <br/><br/>
    Adresse,
    <br/><br/>
    Bankverbindung
    <br/><br/>
    Zählernummer,
    <br/><br/>
    Aktueller Zählerstand
    <br/><br/>
    Deine Bankverbindung
    <br/><br/>
    Stromverbrauch pro Jahr.
    <br/><br/>
    Selbstverständlich kannst Du uns Deine Anmeldung auch per Post zukommen lassen. Ist Dein Antrag bei uns eingegangen, beginnen wir zeitnah mit der Bearbeitung, damit Du schon bald unseren umweltfreundlichen Strom nutzen kannst. Das kann bis zu zwei Wochen dauern, am besten schließt Du den Vertrag also schon ab, bevor Du in die neue Wohnung ziehst. Bist Du bereits eingezogen, kannst Du dich entsprechend der GPKE (Geschäftsprozesse zur Kundenbelieferung mit Elektrizität der Bundesnetzagentur) noch bis zu sechs Wochen rückwirkend bei uns anmelden. Andernfalls tritt automatisch ein Vertrag mit dem Grundversorger in Kraft.
    `,
    question_8: 'Wie funktioniert das Stromabmelden bei Auszug aus der Wohnung?',
    answer_8: `
    Ziehst Du aus Deiner Wohnung aus, benötigen wir von Vegan Strom bereits im Voraus ein paar Informationen, um Dich fristgerecht abmelden zu können. Kontaktiere uns am besten spätestens sechs Wochen vor Deinem Auszug und beachte die in Deinem Vertrag festgelegte Kündigungsfrist. Bei Umzug oder Preiserhöhung kannst Du Dein Sonderkündigungsrecht geltend machen.
    <br/><br/>
    Deine Abmeldung muss in Schriftform erfolgen, also beispielsweise per Brief, E-Mail oder Fax. Nenne uns bitte in dem Schreiben das genaue Datum des Auszugs sowie Deine Kundenummer und teile uns außerdem die Zählernummer und den Zählerstand mit. Hierfür reicht es, wenn Du ein Foto vom Stromzähler machst und dieses in unserem Portal hochlädst. Nachdem uns Deine Abmeldung erreicht hat, senden wir Dir eine Bestätigung zu.
    <br/><br/>
    Übrigens: Möchtest Du nur in eine neue Wohnung ziehen, aber weiter nachhaltige Energie von Vegan Strom beziehen, ist das kein Problem! Teile uns einfach das Auszugsdatum, Zählernummer und Zählerstand Deiner alten Wohnung sowie das Einzugsdatum, Zählernummer und Zählerstand Deiner neuen Wohnung mit und wir melden Dich um. Dazu kannst Du auch das Ummelde-Formular auf unserer Website nutzen.
    `,
    question_9: 'Wie funktioniert das Stromummelden bei Umzug von einer Wohnung in die andere?',
    answer_9: `
    Steht bei Dir ein Umzug an, kannst Du selbstverständlich auch in Deiner neuen Wohnung nachhaltige, tierfreundliche Energie von Vegan Strom beziehen. Setze Dich am besten spätestens sechs Wochen vor dem Auszug aus Deiner alten Wohnung mit uns in Verbindung und teile uns Deine Kundennummer, das exakte Auszugsdatum, die Zählernummer und den Zählerstand mit. Dazu reicht es, wenn Du ein Foto vom Stromzähler machst und dieses in unserem Portal hochlädst.
    <br/><br/>
    Darüber hinaus benötigen wir ebenfalls das Einzugsdatum für die neue Wohnung sowie Deine neue Zählernummer und den entsprechenden Zählerstand. Die Informationen kannst Du uns schriftlich zukommen lassen (also per Brief, E-Mail bzw. Fax) oder das entsprechende Formular auf der Website von Vegan Strom nutzen. Ist alles Erforderliche bei uns eingegangen, erhältst Du von uns ein Schreiben, in dem wir Deinen Umzug bestätigen.
    <br/><br/>
    Die Ummeldung beim Netzbetreiber übernehmen wir für Dich. Um mehr brauchst Du Dich nicht zu kümmern!
    `,
    question_10: 'Was beinhaltet die Preisgarantie von Vegan Strom?',
    answer_10: `
    Die Strompreise steigen seit Jahren kontinuierlich an. Daher kann es durchaus sinnvoll sein, einen günstigen Stromtarif mit einer möglichst langen Preisgarantie von beispielsweise 12 Monaten abzuschließen und sich den günstigen Preis für diese Zeit zu sichern.
    <br/><br/>
    Wie bei allen Stromanbietern legen auch bei Vegan Strom die Tarife mit Preisgarantie den Preis für einen bestimmten Zeitraum fest. Die Preisgarantie gilt bei uns ab dem Lieferbeginn für wahlweise sechs Monate oder ein Jahr.
    <br/><br/>
    <b>Welche Formen der Preisgarantie gibt es</b>
    <br/><br/>
    Die Strompreise in Deutschland setzen sich aus verschiedenen Bestandteilen zusammen, von denen einige gar nicht im Einflussbereich der Energieversorger liegen. Daher umfassen die meisten Preisgarantien nur einen bestimmten Teil des Endpreises. Varianten für eine Preisgarantie sind:
    <br/><br/>
    Vollständige Preisgarantie. Hier werden sowohl der Energiekostenanteil als auch alle Steuern, Gebühren und Umlagen garantiert. Nur Änderungen der Mehrwert- und der Stromsteuer dürfen weitergegeben werden.
    Eingeschränkte Preisgarantie. Diese garantiert die Stabilität des Energiekostenanteils und der Nutzungsentgelte für den Netzbetreiber. Werden staatliche Steuern, Abgaben oder Umlagen während der Laufzeit geändert, kann der Preis entsprechend angehoben werden.
    Diese garantiert nur den Energiekostenanteil. Erhöhungen von Steuern und Abgaben, aber auch von Netzentgelten können direkt an den Kunden weitergegeben werden.
    <br/><br/>
    <b>Was garantiert Vegan Strom?</b>
    <br/><br/>
    Bei Vegan Strom gilt eine eingeschränkte Preisgarantie. Wir garantieren also für den vereinbarten Zeitraum, dass weder der Strompreis noch die Kosten die Netznutzung für unsere Kunden angehoben werden. Beschließen staatliche Stellen jedoch eine Anhebung von Steuern, Abgaben oder Umlagen, so müssen wir unseren Stromtarif entsprechend anpassen. Dauerhaft garantiert ist bei Vegan Strom aber auf jeden Fall der ethische Anspruch. Tierwohl und ein nachhaltiger, klimaschonender Umgang mit unserer Erde sind unser wichtigstes Anliegen.
    `,
    question_11:
      'Besteht bei einer Strompreiserhöhung ein Sonderkündigungsrecht in meinem Stromvertrag?',
    answer_11: `
    Wie bei jedem Vertrag ist auch im Vertrag mit Deinem Stromanbieter genauestens geregelt, wann Du diesen kündigen kannst und wie Du dabei vorgehst. Beziehst Du Energie vom Grundversorger, beispielsweise den örtlichen Stadtwerken, beträgt diese Frist meist zwei Wochen. Anders sieht es aus, wenn Du Dich für einen Sondertarif, einen Vertrag mit Mindestlaufzeit oder einen alternativen Anbieter entschieden hast. Dann kann der Kündigungszeitraum deutlich länger sein. Aber auch hier gibt es eine Ausnahme: Zieht Dein früherer Energieanbieter die Preise an, eröffnet Dir das die Möglichkeit, schnell und einfach zu Vegan Strom zu wechseln. Denn bei einer Strompreiserhöhung besteht ein Sonderkündigungsrecht – Deinen Stromvertrag kannst Du deshalb unabhängig von den sonst gültigen Fristen auflösen. Diese Regelung ist im Energiewirtschaftsgesetz festgelegt. Hier gelten in der Regel sehr kurze Fristen, sodass die direkte Kündigung des Verbrauchers beim Energieversorger besser ist als den Wechselprozess zu beschreiten.
    <br/><br/>
    <b>Strompreiserhöhung & Sonderkündigungsrecht: Stromvertrag kündigen – so geht's</b>
    <br/><br/>
    Möchtest Du von Deinem Sonderkündigungsrecht Gebrauch machen, sende Deinem bisherigen Anbieter einfach Deine Kündigung in Textform. Das kann beispielsweise ein Brief, ein Fax oder eine E-Mail sein. Wichtig ist es, dass Du in dem Schreiben auf Dein Sonderkündigungsrecht verweist und Deine Verbrauchstelle (also die Zählernummer), Deinen vollständigen Namen und Deine Adresse angibst. Für uns von Vegan Strom ist es außerdem hilfreich, wenn Du in Deinem Antrag an uns vermerkst, dass Du den Vertrag mit Deinem alten Energielieferanten bereits gekündigt hast. Innerhalb kurzer Zeit erhältst Du dann nachhaltigen und tierfreundlichen Ökostrom auf Basis von Sonnenenergie und Geothermie. Falls es unerwartete Komplikationen geben sollte, musst Du Dir übrigens keine Sorgen machen, dass kein Strom mehr aus Deiner Steckdose kommt: Im Notfall springt immer der Grundversorger ein.
    `,
    question_12: 'Wie wird der durchschnittliche Stromverbrauch oder Jahresverbrauch ermittelt?',
    answer_12: `
    Der durchschnittliche Stromverbrauch beziehungsweise Jahresverbrauch an Strom eines Haushalts ist logischerweise abhängig von der Anzahl der im Haushalt lebenden Personen. Gleichzeitig haben aber auch Anzahl und Alter der elektrischen Geräte sowie das Konsumverhalten der Bewohner Einfluss auf den Verbrauch. Gerechnet wird über den Zeitraum von einem Jahr – deswegen wird der Stromverbrauch als durchschnittlicher Jahresverbrauch angegeben. Grund dafür sind die jahreszeitlich bedingten Schwankungen beim Verbrauch: Im Sommer, wenn Klimaanlagen und Kühlgeräte laufen, und im Winter, wenn mehr Licht an ist und mehr geheizt wird, ist der Stromverbrauch höher als im Frühjahr und im Herbst.
    <br/><br/>
    Über das Jahr gerechnet, benötigt ein Ein-Personen-Haushalt in Deutschland durchschnittlich rund 2.000 Kilowattstunden (kWh), ein Drei-Personen-Haushalt ca. 3.000-4.000 kWh und ein Haushalt mit 5 Personen ungefähr 6.000 kWh. Wird das Warmwasser mit Strom geheizt, liegen die Werte höher. Ermittele einmal Deinen eigenen Stromverbrauch und schaue, ob es Dir gelingt unter dem Durchschnitt zu bleiben!
    <br/><br/>
    <b>Wie kann ich meinen eigenen Stromverbrauch ermitteln?</b>
    <br/><br/>
    Deinen eigenen Stromverbrauch kannst Du ganz einfach am Stromzähler ablesen. In jedem Haushalt gibt es einen Stromzähler, meist im Flur oder im Keller. Notiere Dir den Zählerstand und lies dann monatlich den neuen Wert ab: So kannst Du den Verbrauch für bestimmte Zeiträume erkennen und ganz einfach den Jahresverbrauch bestimmen. Den Stromverbrauch auf ein Jahr gerechnet kannst Du aber natürlich auch Deiner Stromrechnung entnehmen.
    <br/><br/>
    Möchtest Du wissen, wie viel Strom einzelne Elektrogeräte verbrauchen, kann ein Strommesser helfen. Solche Strommessgeräte gibt es in Elektrogeschäften. Sie werden einfach in die Steckdose gesteckt, in die dann das jeweilige Elektrogerät eingesteckt wird. So lassen sich „Stromfresser“, die die Stromrechnung in die Höhe treiben, perfekt erkennen. Zur Ermittlung des Jahresverbrauchs oder des Stromverbrauchs für den gesamten Haushalt sind solche Strommesser jedoch eher ungeeignet, denn sie messen immer nur den aktuellen Verbrauch eines Geräts.
    <br/><br/>
    Ein niedriger Stromverbrauch schont nicht nur den eigenen Geldbeutel, sondern auch die Umwelt profitiert. Wenn Du mit Vegan Strom zusätzlich auf den ganzheitlichen Gedanken setzt, trägst Du außerdem zu Tierwohl und Klimaschutz bei.
    `,
    question_13: 'Stromzähler ablesen: Das solltest Du beachten',
    answer_13: `
    Einmal im Jahr stellt Dir Vegan Strom Deinen Stromverbrauch in Rechnung. Damit wir das machen können, benötigen wir Deinen aktuellen Zählerstand. Dazu schickt der Netzbetreiber eine Person zum Ablesen vorbei und leitet den Zählerstand dann an uns weiter. Es kann aber auch sein, dass wir Dich auffordern, Deinen Zählerstand selbst abzulesen. Keine Angst, das ist nicht schwer! Deinen Stromzähler findest Du meist im Keller oder im Hausflur, manchmal auch direkt in der Wohnung. Hast Du das Gerät gefunden, sind für uns zwei Informationen wichtig: der Zählerstand, also Dein Stromverbrauch in Kilowattstunden (kWh), und die Zählernummer, also die Nummer Deiner persönlichen Verbrauchsstelle. Diese findest Du übrigens auch auf einer alten Stromrechnung.
    <br/><br/>
    <b>Wie gehe ich beim Ablesen des Stromzählers vor?</b>
    <br/><br/>
    Die verschiedenen Stromzähler unterscheiden sich je nach Modell und Bauart, in den meisten Wohnungen und Häuser sind aber Drehstromzähler installiert. Deine Zählernummer findest Du hier ober- oder unterhalb der integrierten Drehscheibe. Darüber hinaus siehst Du ein Rollenzählwerk mit einer Zahlenkombination. Diese gibt Deinen aktuellen Zählerstand an. Bitte teile uns diese Zahl mit, den Nachkommabereich musst Du aber nicht übermitteln. Einige Drehstromzähler sind mit zwei Rollenzählwerken ausgestattet (HT für Hochtarif, NT für Niedertarif). Diese zeigen an, wie viele Kilowattstunden Du tagsüber bzw. nachts verbraucht hast. Bitte teile uns auch hier beide Zahlen bis zur Kommastelle mit. In einigen Häusern sind bereits sogenannte „Smart Meter“, also digitale Stromzähler verbaut. Das Ablesen ist auch hier ganz einfach: Die Zählernummer findest Du häufig in der Nähe des aufgedruckten Barcodes, den Zählerstand erfährst Du, indem Du Dir per Knopfdruck die Daten für „Bezug Gesamt“ (1.8.0) anzeigen lässt.
    <br/><br/>
    Grundsätzlich kannst Du uns von Vegan Strom Deinen Zählerstand auch mitteilen, indem Du mit Deinem Smartphone oder Deiner Kamera ein Foto des Stromzählers machst. Anschließend einfach in unserem Portal hochladen – und fertig!
    `,
    question_14: 'Was ist der Unterschied zwischen Grundpreis und Arbeitspreis?',
    answer_14: `
    Entscheidest Du Dich für umweltfreundliche, tierfreundliche Energie von Vegan Strom, siehst Du auf Deiner Rechnung zwei unterschiedliche Positionen: den Grundpreis und den Arbeitspreis. Der Grundpreis ist der Betrag, der monatlich für Deinen Stromanschluss berechnet wird. Das schließt verschiedene Leistungen mit ein, unter anderem die Kosten, die aufgrund der Netznutzung entstehen, den Betrieb der Messstelle (also Deines Stromzählers) und gegebenenfalls das Ablesen des Zählers durch den Betreiber der Messstelle. Der Arbeitspreis wiederum ist der Betrag, der pro Kilowattstunde Strom fällig wird. Diese Position zeigt also an, wie viel Strom Du nutzt und wird deshalb auch als Verbrauchspreis bezeichnet.
    `,
    question_15: 'Worin unterscheiden sich Gewerbestrom und Hausstrom?',
    answer_15: `
    Gewerbestrom oder auch Industriestrom auf der einen und Hausstrom oder Privatstrom auf der anderen Seite? Ist Strom nicht gleich Strom? Doch, aber je nach Abnehmer gibt es Unterschieden bei den Konditionen, der Preiszusammensetzung und der Abrechnung. Mit Gewerbestrom oder Industriestrom wird die Stromversorgung für Unternehmen bezeichnet. Privat- oder Hausstrom ist der Stromverbrauch von Privatpersonen und Haushalten. Die wichtigsten Unterschiede zwischen Gewerbestrom und Hausstrom findest Du hier auf veganstrom.com erläutert:
    <br/><br/>
    <b>Verbrauch und Konditionen.</b> Wichtiger Faktor bei der Unterscheidung zwischen Gewerbestrom und Hausstrom ist die abgenommene Menge. Gewerbe braucht mehr Energie: Der Durchschnittsverbrauch von Privathaushalten liegt zwischen 2.000 und 6.000 kWh pro Jahr, bei größeren Betrieben kann er leicht 10.000.000 kWh betragen. Auch die Tageszeiten und die Konstanz der Abnahme spielen eine Rolle. Denn für größere Unternehmen wird vom Stromversorger in der Regel kein Standardtarif veranschlagt, sondern anhand der Parameter ein spezielles Angebot berechnet.
    <br/><br/>
    <b>Preiszusammensetzung.</b> Grundsätzlich ähnelt sich die Preiszusammensetzung bei Gewerbestrom und Hausstrom. Etwa 24 % des Preises entfällt auf die Leistung des Energieanbieters, 48 % sind staatliche Steuern und Umlagen und 28 % entfallen auf die Entgelte für den Netzbetreiber. Im Bereich der Netzentgelte sowie Umlagen und Steuern gibt es für Unternehmen jedoch Sonderregelungen, die dazu führen, dass die Steuern und Umlagen statt 48 % teilweise nur 15 bis 30 % betragen, wodurch Gewerbestrom günstiger als Hausstrom
    <br/><br/>
    <b>Verbrauchsmessung und Abrechnung.</b> Ein weiterer Unterschied zwischen Gewerbestrom und Hausstrom besteht bei der Verbrauchsmessung und Abrechnung. Privatkunden zahlen eine monatliche Pauschale, die auf der Grundlage ihrer letzten Jahresabrechnung ermittelt wurde. Mit der nächsten Jahresabrechnung werden die geleisteten Vorauszahlungen dann mit dem tatsächlichen Verbrauch verrechnet. Bei kleineren Unternehmen gilt das gleiche Verfahren. Bei größeren Kunden wird dagegen eine registrierende Leistungsmessung (RLM) vorgenommen. Bei diesem Messvorgang werden die Kundenverbrauchsdaten automatisch im 15 Minuten-Takt an den Verteilungsnetzbetreiber übermittelt und dienen zur monatlichen Abrechnung des tatsächlichen Verbrauchs.
    `,
    question_16: 'Ist Ökostrom bzw. Naturstrom zwangsläufig teuer?',
    answer_16: `
    Die Auswirkungen des Klimawandels haben bei vielen Menschen ein Umdenken in Bezug auf ihren Konsum bewirkt. Das betrifft auch den Bereich Energie. Immer mehr Haushalte greifen auf nachhaltigen Ökostrom bzw. Naturstrom, der auf Basis Erneuerbarer Energien hergestellt wird, zurück. Und das ist – entgegen trotz vieler Befürchtungen – nicht teuer! Vor allem im Vergleich zu den Grundversorgern ist das Preisniveau von Ökostromanbietern wie Vegan Strom oftmals sogar günstiger. Obwohl hier neben Kosten für die erforderlichen Zertifikate auch die Umlagen zur Förderung der Energiewende entsprechend des Erneuerbare-Energien-Gesetzes (EEG) enthalten sind. Nachhaltige Energieanbieter sind also durchaus konkurrenzfähig.
    <br/><br/>
    Ebenso werden bei Atom- und Kohlestrom die externen Kosten – zum Beispiel die staatliche Förderung, Umweltschäden durch CO2-Emissionen oder Endlagerungskosten für Atommüll – nicht wirklich im Preis berücksichtigt. Die Gesellschaft muss jedoch einen Großteil davon tragen (z. B. die Suche nach einem geeigneten Endlager). Würde man diese Kosten mit in den Preis für konventionellen Strom einrechnen, wäre dieser also noch einmal merklich höher als der von Ökostrom.
    <br/><br/>
    Darüber hinaus ist ein nachhaltiger und bewusster Umgang mit den uns zur Verfügung stehenden, natürlichen Ressourcen maßgeblich für den Fortbestand unserer Gesellschaft und des Planeten Erde. Denn wir von Vegan Strom finden: Umweltschutz ist nicht in GelDeinheiten messbar!
    `,
    question_17: 'Wer steht hinter Vegan Strom?',
    answer_17: `
    Hinter Vegan Strom steht das Unternehmen GreenStone Energy aus Berlin. Green Stone Energy hat sich zum Ziel gesetzt, Verantwortung zu übernehmen, um den negativen Auswirkungen des Klimawandels gezielt entgegenzutreten. Dazu setzt das Unternehmen ausschließlich auf umweltfreundlichen Ökostrom aus Erneuerbaren Energien, die Tiere und deren Lebensräume schützen – also vorwiegend Solarstrom und Geothermie..
    <br/><br/>
    Um die Stromversorgung für Kunden zu realisieren kooperiert der Stromlieferant mit Energieerzeugern, die ihre nachhaltige Energie in den umweltfreundlichen Ökostrom-Mix einbringen. Das breite Produktportfolio bestehend aus Einkauf & Akquise, Transport & Verteilung, Speicherung von Energie, Trading und Zertifikaten. Das macht GreenStone Energy nicht nur attraktiv für Endkunden aus dem privaten und geschäftlichen Bereich. Auch Netzbetreiber, Handelsunternehmen, Stadtwerke und Investoren gehören zu den Geschäftspartnern. Intelligente, ausfallsichere Netze, die unabhängig von der Tageszeit und den Wetterbedingungen die Bedarfe der angeschlossenen Haushalte decken, sind das Ziel des Unternehmens. Um dies zu realisieren, ist GreenStone Energy aktiv an der Entwicklung der dazu nötigen Software beteiligt.
    `,
    // Tab old version
    supply: 'Lieferung',
    payment: 'Zahlung',
    order: 'Bestellung',
    energy_change: 'Stromwechsel',
    customer_account: 'Kundenkonto',
  },
  widget_faq_referral: {
    title:
      'Die häufigsten Fragen haben wir für Dich hier gesammelt und geben gleich die Antworten.',
    question_1: 'Was ist die Freunde-werben-Freunde Aktion?',
    question_2: 'Wie hoch ist die Prämie für einen geworbenen Kunden?',
    question_3: 'Auf welchen Stromtarif bezieht sich die Freunde-werben-Freunde Aktion?',
    question_4: 'Was beinhaltet der Stromtarif „clever“?',
    question_5: 'Wie oft kann ich Freunde werben?',
    question_6: 'Wie kann ich bei der Freunde-werben-Freunde Aktion mitmachen?',
    question_7: 'Gibt es bestimmte Voraussetzungen, um mitzumachen?',
    question_8: 'Muss ich bei der Teilnahme etwas beachten?',
    question_9:
      'Was ist, wenn ein Freund einen Vertrag mit Vegan Strom schließt, ohne den Empfehlungscode zu verwenden?',
    question_10: 'Wie lange ist der Empfehlungscode gültig?',
    question_11: 'Wann erhalte ich meine 25,00 € Gutschrift?',
    question_12: 'Wann erhält mein/e Freund/in die Gutschrift?',
    question_13: 'Wofür werden meine persönlichen Daten verwendet?',
    question_14: 'Woher weiß ich, ob meine Freundschaftswerbung erfolgreich war?',
    question_15: 'Gibt es eine Übersicht über all meine Empfehlungen?',
    question_16: 'Was bedeutet der Status „Abgelaufen“ in der Empfehlungsübersicht?',
    question_17: 'Was bedeutet der Status „Erfolgreich“ in der Empfehlungsübersicht?',
    question_18: 'Was bedeutet der Status „Ausgezahlt“ in der Empfehlungsübersicht?',
    question_19: 'Was bedeutet der Status „In Bearbeitung“ in der Empfehlungsübersicht?',
    question_20:
      'Mein/e Freund/in hat aufgrund meiner Empfehlung den Stromtarif „clever“ abgeschlossen. Warum steht in der Empfehlungsübersicht immer noch „In Bearbeitung “?',
    question_21: 'Was bedeutet der Status „fehlgeschlagen“ in der Empfehlungsübersicht?',
    answer_1: `Die „Freunde-werben-Freunde-Aktion“ ist ein exklusives Empfehlungsprogram von Vegan Strom. Hier können unsere Kunden mit wenigen Klicks Freunde einladen auch Kunde von Vegan Strom zu werden. Für jeden erfolgreich geworbenen Kunden wird eine Prämie in Höhe von 25,00 € an beide Parteien ausgezahlt. Die Aktion gilt nur für den Vertragsabschluss des Stromtarifs „clever".`,
    answer_2: `Die Prämienhöhe beträgt insgesamt 50,00 €. Dabei erhalten der Bestandskunde sowie der Neukunde eine Gutschrift in Höhe von 25,00 €. Diese Gutschrift wird direkt mit der nächsten Rechnung verrechnet.`,
    answer_3: `Die Freunde-werben-Freunde Aktion bezieht sich nur auf den Stromtarif „clever“.`,
    answer_4: `Der Stromtarif „clever“ beinhaltet folgendes.
    <ul>
      <li>Tarif unterstützt die Tierschutzorganisation PETA</li>
      <li>24 Monate Mindestvertragslaufzeit</li> 
      <li>24 Monate Preisgarantie</li>
    </ul>`,
    answer_5: `Deinen Empfehlungen sind keine Grenzen gesetzt. Du kannst so viele Freunde werben, wie Du möchtest. Jede erfolgreiche Empfehlung wird belohnt.`,
    answer_6: `Das geht ganz einfach mit einem Klick auf „Freunde werben“. Auf dieser Seite trägst Du einfach den Namen und E-Mail-Adresse Deines Freundes ein. Wenn Du möchtest, kannst Du die Einladung noch mit einer persönlichen Nachricht versehen. Mit einem Klick auf „absenden“ hast Du die Einladung erfolgreich versendet.`,
    answer_7: `Die einzige Voraussetzung ist, dass Du uneingeschränkt geschäftsfähig bist – sprich 18 Jahre.
        <br/>
        Bitte stelle bei einer Einladung sicher, dass Dein/e Freund/in mit dem Erhalt der Einladung unter Verwendung der Daten (E-Mail-Adresse und Name) einverstanden ist.`,
    answer_8: `Wenn Du Freunde zu Vegan Strom einladen möchtest, stelle bitte sicher, dass Dein/e Freund/in mit dem Erhalt der Einladung unter Verwendung der persönlichen Daten (E-Mail- Adresse und Name) einverstanden ist.`,
    answer_9: `In diesem Fall können wir die Prämie leider nicht auszahlen. Es ist wichtig, dass Dein/e Freund/in den Rabattcode verwendet.`,
    answer_10: `Der Empfehlungscode hat eine Gültigkeit von drei Monaten.`,
    answer_11: `Vegan Strom verrechnet Deine Gutschrift mit der nächsten Jahresrechnung.  
        Voraussetzung hierfür ist, dass Dein Freund den Stromtarif „clever“ unter Verwendung des zugesendeten Codes abgeschlossen hat und den Vertrag nicht innerhalb der gesetzlichen Widerrufsfrist von 14 Tagen widerrufen hat.`,
    answer_12: `Die Gutschrift erfolgt nach Lieferungsbeginn in der Empfehlungsübersicht. Verrechnet wird die Gutschrift mit der Jahresrechnung.`,
    answer_13: `Die angegeben persönlichen Daten werden ausschließlich für die Freunde-werben-Freunde Aktion verwendet.`,
    answer_14: `Nach erfolgreicher Werbung ändert sich der Status der Empfehlung in der Empfehlungsübersicht auf „erfolgreich“. Außerdem wirst Du per Mail von uns informiert.`,
    answer_15: `Ja, die gibt es. In der Rubrik „Freunde werben“ findest Du eine Registerkarte „Empfehlungsübersicht“. In dieser Empfehlungsübersicht siehst Du all Deine Empfehlungen inkl. Name des Freundes, Datum und Status der Empfehlung aufgelistet.`,
    answer_16: `Der Status „Abgelaufen“ besagt, dass Dein versendeter Gutscheincode abgelaufen ist. Dies ist nach drei Monaten nach Versand der Fall. Aber keine Sorge, Du kannst die Einladung einfach erneut versenden und wir generieren einen neuen Code für dich.`,
    answer_17: `Der Status „Erfolgreich“ besagt, dass Deine Freundschaftswerbung geglückt ist. Dein/e Freund/in hat erfolgreich den Stromtarif Vegan Strom „clever“ abgeschlossen. Ihr bekommt nun beide eine Gutschrift in Höhe von 25,00 €.`,
    answer_18: `Der Status „Ausgezahlt“ besagt, dass die Gutschrift in Höhe von 25,00 € erfolgreich mit Deiner Rechnung verrechnet wurde. Falls Du Deine Rechnung gerne einsehen möchtest, kannst Du das ganz einfach im Vegan Strom Kundenportal unter “Dokumente” machen.`,
    answer_19: `Der Status „In Bearbeitung“ besagt, dass der Gutscheincode von Deinem/er Freund/in im Bestellprozess eingelöst wurde. Sobald die erste Abschlagszahlung von Seiten des Eingeladenen beglichen ist, erhaltet ihr umgehend die Gutschrift. Die Auszahlung der Gutschrift erfolgt durch die Verrechnung mit der Jahresendabrechnung.`,
    answer_20: `Sollte Dein/e Freund/in bereits einen Vertrag geschlossen haben, dauert es ein paar Tage bis die erste Abschlagszahlung von Deinem/r Freund/in beglichen ist. Grund dafür ist u.a die gesetzliche Widerrufsfrist.`,
    answer_21: `Dem Status „fehlgeschlagen“ können zwei Gründe zugrunde liegen. Entweder hat Dein/e Freund/in innerhalb von drei Monaten keinen Stromvertrag mit Vegan Strom geschlossen hat, <b>oder</b> der Vertrag wurde geschlossen, er wurde allerdings innerhalb von 14 Tagen widerrufen.`,
  },
  widget_document: {
    documents: 'Dokumente',
    energy_account: 'Stromkonto',
    download: 'Download',
    balance_at: 'Kontostand am',
    document_header_document_date: 'Datum',
    document_header_document_type: 'Dokumentenart',
    document_header_billing_gross_amount_sum: 'Betrag',
    document_header_actions: 'Aktionen',
    document: 'Dokumente',
  },
  widget_metering: {
    title: 'Alle erfassten Zählerstände im Überblick',
    metering_header_meter_number: 'Zählernummer',
    metering_header_reading_date: 'Ablesedatum',
    metering_header_meter_reading: 'Ableseergebnis',
    metering_header_reason_dso_accurate: 'Ablesegrund',
    metering_header_source: 'Übermittelt durch',
  },
  widget_newsletter_sub: {
    title: 'Newsletter abonnieren (Abmeldung jederzeit möglich)',
    text: 'Um dich für unseren Newsletter anzumelden, trage bitte hier Deine E-Mail-Adresse ein und klicken anschließend auf abonnieren.',
    radio_woman: 'Frau',
    radio_man: 'Herr',
    radio_other: 'Divers',
    first_name: 'Vorname',
    last_name: 'Nachname',
    email: 'E-Mail',
    button_text_subscribe: 'Abonnieren',
    button_text_reset: 'Felder zurücksetzen',
    text_required: 'Dies ist ein Pflichtfeld',
  },
  widget_referral: {
    tab_referral: 'Empfehlen',
    tab_referral_overview: 'Empfehlungsübersicht',
    title: 'Gemeinsam 50 € sparen und <nobr>Tiere & Umwelt</nobr> schützen',
    text: `Du hast Freunde, Familie oder Bekannte, denen die Umwelt und Tiere genauso sehr am Herzen liegen wie Dir? Dann empfehle ihnen VeganStrom.`,
    text_2: `
      <br/>
      Na, wenn das mal kein Grund ist! Füll einfach das unten stehende Formular aus, füge eine persönliche Nachricht an und klicke auf „Absenden“. Sobald Deine Freund: innen einen Vertrag mit uns abgeschlossen haben, werden jedem von euch die 25 Euro gut geschrieben.`,
    email: 'E-mail',
    name_friend: 'Name des Freundes',
    email_friend: 'E-Mail des Freundes / Familienmitglieds',
    subject: 'Betreff',
    message: 'Deine persönliche Nachricht',
    bonus_selection: 'Prämienauswahl',
    bonus_1: '50 Euro Gutschrift, 25 Euro für dich und 25 Euro für Dein/en Freund/in',
    bonus_2: '50 Euro Gutschrift aufs eigene Konto',
    bonus_3: 'Prämie 2 (z. B. Prämienverzicht)',
    button_text_send: 'Absenden',
    button_text_send_wa: 'Auf WhatsApp teilen',
    button_text_reset: 'Felder zurücksetzen',
    text_required: 'Dies ist ein Pflichtfeld',
    text_max_letters: 'Nachricht darf maximal 300 Zeichen haben.',
    referral_amount_text:
      'Großartig! Du hast bereits {referralAmount} erfolgreiche Weiterempfehlungen getätigt.',
    referral_left_text:
      'Empfehle VeganStrom noch {referralAmountLeft} weiteren Freunden und Du erhältst einen Extrabonus in Höhe von 20€.',
  },
  widget_referral_simple: {
    title: 'Freunde werben',
    text: 'Empfehle VeganStrom Deinen Freund:innen und sichert euch zusammen 50 Euro Prämie.',
    button_text: 'Zur Aktion',
  },
  widget_voucher: {
    title: 'Gutscheincode',
    text: 'Jetzt Gutscheincode eingeben und 10% sparen!',
    voucher_code: 'Gutscheincode',
    button_text_send: 'Senden',
  },
  widget_co2_calculation: {
    TODO: 'TODO',
  },
  widget_meter_upload: {
    title: 'Schicke uns Deinen aktuellen Zählerstand',
    alert_success: 'Zählerstand erfolgreich übermittelt!',
    alert_error: 'Zählerstand konnte leider nicht übermittelt werden.',
    text_required: 'Dies ist ein Pflichtfeld',
    button_text_send: 'Zählerstand übermitteln',
    name: 'Name',
    email: 'E-Mail',
    meter_number: 'Zählernummer',
    meter_reading: 'Zählerstand in kWh ohne Nachkommastellen',
    meter_reading_date: 'Ablesedatum',
    obis_code: 'OBIS Code',
  },
  widget_sepa_mandate: {
    title: 'Erteile uns ganz einfach das SEPA-Lastschriftmandat',
    alert_success: 'Anfrage erfolgreich übermittelt!',
    alert_error: 'Es gab leider ein Problem bei Deiner Anfrage.',
    text_required: 'Dies ist ein Pflichtfeld',
    button_text_send: 'SEPA-Lastschriftmandat erteilen',
    account_owner: 'Name Kontoinhaber*',
    email: 'E-Mail Kontoinhaber*',
    iban: 'IBAN*',
    bic: 'BIC (automatisch ermittelt)',
    bank: 'Kreditinstitut (automatisch ermittelt)',
    mandate_accept:
      'Ich ermächtige/ Wir ermächtigen Greenstone Energy GmbH, Zahlungen von meinem/ unserem Konto mittels Lastschrift einzuziehen. Zugleich weise ich mein/ weisen wir unser Kreditinstitut an, die von Greenstone Energy GmbH auf mein/ unser Konto gezogenen Lastschriften einzulösen.*',
    mandate_note:
      'Hinweis: Ich kann/ Wir können innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem/ unserem Kreditinstitut vereinbarten Bedingungen.',
    mandate_document_text:
      "Alternativ kannst Du auch das folgende Dokument ausfüllen und an <a target='_blank' href='mailto:service@veganstrom.com'>service@veganstrom.com</a> senden.",
    sepa_mandate: 'SEPA-Lastschriftmandat.pdf',
  },
  widget_sepa: {
    title: 'Möchtest Du die Vorzüge des SEPA-Lastschriftverfahrens nutzen?',
    text: 'Dann fülle einfach unser Onlineformular aus. Danach musst Du dich nicht mehr um lästige Überweisungen kümmern.<br> Wir buchen Deinen Abschlag ganz bequem monatlich von Deinem Konto ab.',
    button_text: 'Zum Formular',
  },
  widget_meter_upload_small: {
    title: 'Aktuellen Zählerstand hochladen',
    text: 'Hier kannst Du uns Deinen aktuellen Zählerstand mitteilen.',
    button_text: 'Zählerstand mitteilen',
  },
}
