<template>
  <div class="text">
    <div class="container-padding">
      <!-- Header -->
      <div
        :style="`${
          $vuetify.breakpoint.mobile ? 'height: 120px' : 'height: 95px; position: relative;'
        } width: 100%; background-color: white; z-index: 2`">
        <v-row class="width-container mx-auto mt-2" align="center">
          <v-col style="display: contents">
            <img
              class="ml-10 mt-3"
              style="max-width: 240px"
              src="../../public/images/logo-veganstrom.svg" />
            <p style="margin-top: 38px; margin-left: 5px; font-weight: 600" class="text-caption">
              Gegen Tierleid
            </p>
          </v-col>
          <v-col
            :align="`${$vuetify.breakpoint.mobile ? 'middle' : 'right'}`"
            :class="`${$vuetify.breakpoint.mobile ? 'pt-1' : 'pt-7'}`">
            <a
              class="mr-10"
              href="https://www.veganstrom.com/hausstrom/"
              target="_blank"
              style="font-size: 1.2rem; color: black; text-decoration: none">
              Tarifrechner
            </a>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-sheet color="#eff9f2" class="pt-10" style="height: calc(100vh - 105px)">
      <v-container id="MeterUpload" fluid class="container-padding">
        <v-row justify="center">
          <v-col>
            <v-card class="pa-md-10 pa-sm-6 pa-4 mt-4 mb-6" rounded="xl">
              <v-form ref="formMeterUpload" class="black--text" lazy-validation>
                <div v-if="dataLoaded">
                  <h2 class="my-4 mb-10" style="font-size: 2.4rem; font-weight: 500">
                    Formular ausfüllen und Zählerstände hochladen
                  </h2>
                  <p
                    class="mb-8"
                    style="font-size: 15px; font-weight: 600; font-family: Manrope, sans-serif">
                    {{
                      'Hallo ' +
                      customer_data.customer.first_name +
                      ' ' +
                      customer_data.customer.last_name
                    }},
                    <br />
                    <br />
                    bitte lade hier ganz einfach Deine Zählerstände hoch. Du erhälst eine
                    Bestätigung per E-Mail, sobald wir Deine Daten erhalten haben.
                    <br />
                    Solltest Du mehrere Zähler haben, Dir jedoch aktuell noch nicht alle Daten für
                    jeden einzelnen Zähler vorliegen, so ist das kein Problem. Gib einfach die Daten
                    ein, die Du im Moment hast, Du kannst die fehlenden Daten dann zu einem späteren
                    Zeitpunkt eingeben.
                    <br />Der Link ist bis zum
                    <span class="info--text">{{
                      new Date(customer_data.validity_end_date).toLocaleDateString('de-DE', {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                      })
                    }}</span>
                    aktiv.
                  </p>
                  <b class="mr-2" style="font-weight: 500; font-size: 1rem">Legende:</b>
                  <v-chip small class="mb-1" style="font-family: Manrope"
                    >Noch nicht hochgeladen</v-chip
                  >
                  <v-chip small style="font-family: Manrope" class="mx-1 mb-1" color="success">
                    Hochgeladen
                  </v-chip>
                  <v-chip small class="mb-1" style="font-family: Manrope" color="error"
                    >Fehler beim Hochladen</v-chip
                  >
                  <h3 class="mt-4">Zählernummer auswählen:</h3>
                  <v-chip
                    v-for="meter_number in customer_data.meter_numbers"
                    :key="meter_number.meter_number"
                    :color="meter_number.state"
                    :style="`font-family: Manrope; cursor: pointer; ${
                      meter_number_selected &&
                      meter_number.meter_number == meter_number_selected.meter_number
                        ? ' box-shadow: #4b86ed 0px 0px 10px;'
                        : ''
                    }`"
                    :class="`my-2 mr-1`"
                    :disabled="meter_number.state == 'success'"
                    @click="selectMeterReading(meter_number)">
                    {{ meter_number.meter_number }} (Vertrag {{ meter_number.contract_number }})
                  </v-chip>
                </div>
                <div v-if="meter_number_selected" class="mt-6">
                  <div
                    v-for="obis_code in meter_number_selected.obis_codes"
                    :key="meter_number_selected.meter_number + obis_code.obis_code">
                    <span v-if="obis_code.last_meter_reading">
                      Dein letzter Zählerstand
                      {{
                        meter_number_selected.obis_codes.length > 1
                          ? 'für den OBIS-Code ' + obis_code.obis_code
                          : ''
                      }}
                      war {{ Number(obis_code.last_meter_reading) }} kWh am
                      {{ obis_code.last_meter_reading_date }}.
                    </span>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="
                            meter_reading_values[meter_number_selected.meter_number][
                              obis_code.obis_code
                            ].value
                          "
                          class="red-hint"
                          style="font-family: Manrope"
                          :label="` ${
                            meter_number_selected.obis_codes.length > 1
                              ? 'Zählerstand für OBIS-Code ' + obis_code.obis_code
                              : 'Zählerstand'
                          }`"
                          :rules="[
                            v => !!v || 'Bitte ausfüllen!',
                            v => !/[.,]/.test(v) || 'Bitte ohne Nachkommastellen eingeben',
                            v => /^\d+$/.test(v) || 'Bitte nur Ziffern eingeben',
                            v =>
                              !obis_code.last_meter_reading ||
                              Number(v) >= Number(obis_code.last_meter_reading) ||
                              'Der Zählerstand darf nicht kleiner sein als der letzte Zählerstand',
                          ]"
                          type="number"
                          :hint="
                            meter_reading_values[meter_number_selected.meter_number][
                              obis_code.obis_code
                            ].hint
                          "
                          persistent-hint
                          required
                          @input="updateNumberHint(obis_code)"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="
                            meter_reading_values[meter_number_selected.meter_number][
                              obis_code.obis_code
                            ].date
                          "
                          style="font-family: Manrope"
                          type="date"
                          label="Ablesedatum*"
                          :rules="[
                            v => !!v || 'Bitte ausfüllen!',
                            v =>
                              new Date(v) <= new Date() || 'Datum darf nicht in der Zukunft liegen',
                          ]"
                          :max="new Date().toISOString().split('T')[0]"
                          required></v-text-field
                      ></v-col>
                    </v-row>
                  </div>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-btn
                        :disabled="meter_number_selected.state == 'success' || showInfoAlertUpload"
                        class="py-6 mt-2 mb-2"
                        color="accent"
                        width="100%"
                        @click="upload_meter_reading">
                        {{
                          meter_number_selected.obis_codes.length > 1
                            ? 'Zählerstände'
                            : 'Zählerstand'
                        }}
                        für Zähler abschicken
                      </v-btn>
                      <v-row class="mt-4">
                        <v-col class="py-0">
                          <v-alert v-if="showSuccessAlertUpload" dense text type="success">
                            Deine Zählerstände wurden erfolgreich verschickt!
                          </v-alert>
                          <v-alert v-if="showInfoAlertUpload" dense text type="info">
                            Anfrage wird bearbeitet
                            <v-progress-circular
                              class="ml-2"
                              indeterminate
                              :size="20"
                              color="info"></v-progress-circular>
                          </v-alert>
                          <v-alert v-if="showErrorAlertUpload" dense text type="error">
                            Leider ist ein Fehler beim hochladen der Zählerstände aufgetreten.
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
                <v-row :class="`${dataLoaded ? 'mt-10' : 'mt-2'}`">
                  <v-col class="py-0">
                    <v-alert v-if="showInfoAlert" dense text type="info">
                      Anfrage wird bearbeitet
                      <v-progress-circular
                        class="ml-2"
                        indeterminate
                        :size="20"
                        color="info"></v-progress-circular>
                    </v-alert>
                    <v-alert v-if="showErrorAlert" dense text type="error">
                      {{
                        error.response.status == 410
                          ? 'Leider ist dieser Link nicht mehr gültig.'
                          : 'Leider ist ein Fehler beim Laden der Zählerdaten aufgetreten. Versuche es bitte erneut oder kontaktiere uns per E-Mail unter service@veganstrom.com.'
                      }}
                    </v-alert>
                  </v-col>
                </v-row>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <FooterComponent />
  </div>
</template>

<script>
import FooterComponent from '../components/FooterComponent'
import { postMeterUploadRequest, getHashedValues } from '../api/api'
import { computeHighNumberHint } from '../api/util'
export default {
  components: { FooterComponent },
  data() {
    return {
      dataLoaded: false,
      error: null,
      showErrorAlert: false,
      showInfoAlert: false,
      showErrorAlertUpload: false,
      showSuccessAlertUpload: false,
      showInfoAlertUpload: false,
      customer_data: {},
      meter_reading_values: {},
      meter_number_selected: null,
    }
  },
  mounted() {
    this.showInfoAlert = true
    getHashedValues(this.$route.query.hash)
      .then(customer_data => {
        this.customer_data = customer_data.data
        for (let meter_number of this.customer_data.meter_numbers) {
          this.meter_reading_values[meter_number.meter_number] = {}
          for (let obis_code of meter_number.obis_codes) {
            this.meter_reading_values[meter_number.meter_number][obis_code.obis_code] = {
              value: '',
              date: new Date().toISOString().slice(0, 10).split('-').join('-'),
              hint: '',
            }
          }
        }
        this.selectMeterReading(this.customer_data.meter_numbers[0])
        this.dataLoaded = true
        this.showInfoAlert = false
      })
      .catch(error => {
        console.log(error)
        this.error = error
        this.showInfoAlert = false
        this.showErrorAlert = true
      })
  },
  methods: {
    updateNumberHint(obis_code) {
      this.meter_reading_values[this.meter_number_selected.meter_number][obis_code.obis_code].hint =
        computeHighNumberHint(
          this.meter_reading_values[this.meter_number_selected.meter_number][obis_code.obis_code]
            .value,
          obis_code.last_meter_reading,
          obis_code.last_meter_reading_date,
          obis_code.high_consumption_factor,
          this.meter_number_selected.consumption,
          this.meter_reading_values[this.meter_number_selected.meter_number][obis_code.obis_code]
            .date
        )
      this.meter_reading_values = { ...this.meter_reading_values }
    },
    selectMeterReading(meter_reading) {
      this.meter_number_selected = meter_reading
    },
    upload_meter_reading() {
      if (this.$refs.formMeterUpload.validate()) {
        let payload = { body: [] }
        for (let oIndex = 0; oIndex < this.meter_number_selected.obis_codes.length; oIndex++) {
          let obis_code = this.meter_number_selected.obis_codes[oIndex].obis_code
          let payload_for_obis_code = {
            contract_id: this.meter_number_selected.contract_id,
            meter_number: this.meter_number_selected.meter_number,
            obis_code: obis_code,
            meter_reading:
              this.meter_reading_values[this.meter_number_selected.meter_number][obis_code].value,
            meter_reading_date:
              this.meter_reading_values[this.meter_number_selected.meter_number][obis_code].date,
          }
          payload.body.push(payload_for_obis_code)
        }
        this.showErrorAlertUpload = false
        this.showInfoAlertUpload = true
        payload.email = this.customer_data.customer.email
        payload.payload = this.$route.query.hash
        postMeterUploadRequest(payload)
          .then(() => {
            this.setMeterNumberState(this.meter_number_selected.meter_number, 'success')
            this.showInfoAlertUpload = false
            this.showSuccessAlertUpload = true
          })
          .catch(error => {
            console.log(error)
            this.setMeterNumberState(this.meter_number_selected.meter_number, 'error')
            this.showInfoAlertUpload = false
            this.showErrorAlertUpload = true
          })
      }
    },
    setMeterNumberState(meter_number, state) {
      this.customer_data.meter_numbers.find(item => item.meter_number === meter_number).state =
        state
    },
  },
}
</script>

<style scoped>
.container-padding {
  padding: 0rem 35rem !important;
}

@media (max-width: 2300px) {
  .container-padding {
    padding: 0rem 25rem !important;
  }
}
@media (max-width: 1904px) {
  .container-padding {
    padding: 0rem 20rem !important;
  }
}
@media (max-width: 1600px) {
  .container-padding {
    padding: 0rem 15rem !important;
  }
}
@media (max-width: 1400px) {
  .container-padding {
    padding: 0rem 10rem !important;
  }
}
@media (max-width: 1264px) {
  .container-padding {
    padding: 0rem 4rem !important;
  }
}
@media (max-width: 960px) {
  .container-padding {
    padding: 0rem 2rem !important;
  }
}
@media (max-width: 600px) {
  .container-padding {
    padding: 0rem 1rem !important;
  }
}
.text,
.text h3,
text p {
  font-family: 'Baloo' !important;
  font-weight: 500 !important;
  line-height: 1.4;
  letter-spacing: normal;
}
</style>
