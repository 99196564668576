<!-- eslint-disable vue/no-v-text-v-html-on-component -->

<template>
  <v-card class="pa-4" elevation="1" rounded="lg" height="100%">
    <!-- Title -->
    <v-card-title class="mb-1 ft-700">
      {{ $vuetify.lang.t('$vuetify.widget_meter_upload.title') }}
    </v-card-title>
    <v-card-text v-if="!error">
      <v-form ref="form" lazy-validation>
        <!-- Row 1 -->
        <h2 class="ft-500 mb-4 mt-4">Deine persönlichen Daten</h2>
        <v-row dense>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="name"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_meter_upload.name')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="email"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_meter_upload.email')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="meter_reading_date"
              :rules="[
                v => !!v || $vuetify.lang.t('$vuetify.widget_meter_upload.text_required'),
                v => new Date(v) <= new Date() || 'Datum darf nicht in der Zukunft liegen',
                v => new Date(v).getFullYear() > 2000 || 'Das Jahr muss später als 2000 sein',
              ]"
              :label="$vuetify.lang.t('$vuetify.widget_meter_upload.meter_reading_date')"
              type="date"
              required
              outlined
              color="blue"
              :max="new Date().toISOString().split('T')[0]"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>
        <p v-if="has_meter_number_changed" class="ft-500">
          Deine alte Zählernummer ist:
          <b>{{ meter_number }}</b
          >.
        </p>
        <p class="ft-500">
          Deine angegeben Zählernummer ist:
          <b>{{ meter_number_new }}</b
          >.
        </p>
        <v-row>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-if="change_meter_number"
              v-model="meter_number_new"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_meter_upload.meter_number')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-btn
              v-if="change_meter_number"
              color="accent"
              height="54px"
              style="display: inline"
              @click="saveNewMeter">
              Speichern
            </v-btn>
          </v-col>
        </v-row>
        <v-btn v-if="!change_meter_number" color="accent" @click="changeMeterNumber">
          Zählernummer ändern?
        </v-btn>
        <br v-show="!change_meter_number" />
        <br v-show="!change_meter_number" />
        <div>
          <!-- Row 2 -->
          <v-row v-if="meter_readings.length > 0" dense>
            <v-col
              v-for="(meter_reading, index) in meter_readings"
              :key="index"
              cols="12"
              md="6"
              lg="4"
              class="pr-3">
              <h2 class="ft-500 mb-4 mt-4">
                Dein Zählerstand
                {{ obis_codes.length > 1 ? 'für den OBIS Code: ' + meter_reading.obis_code : '' }}
              </h2>
              <p v-if="meter_reading.last_meter_reading">
                Dein letzter Zählerstand war:
                {{ Number(meter_reading.last_meter_reading) }} kWh am
                {{ meter_reading.last_meter_reading_date }}.
              </p>
              <p v-else>Wir haben noch keinen Zählerstand für diesen OBIS code.</p>
              <v-text-field
                v-model="meter_reading.meter_reading"
                class="red-hint"
                :rules="getNumberRules(meter_reading)"
                :hint="getHighNumberHint(meter_reading)"
                :label="$vuetify.lang.t('$vuetify.widget_meter_upload.meter_reading')"
                outlined
                type="number"
                color="blue"
                background-color="text_area_background"
                persistent-hint />
            </v-col>
          </v-row>
        </div>
        <v-row v-if="has_meter_number_changed">
          <v-col cols="12" md="6" lg="12" class="pr-3">
            <span class="red--text">
              Da Du auch Deine Zählernummer ändern willst, dauert das Eintragen Deiner Zählerstände
              etwas länger.
            </span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-btn
              width="100%"
              height="54px"
              color="accent"
              class="mr-4 mb-1 text-none ft-700"
              :disabled="validateButtonDisabled"
              @click="validate">
              {{ obis_codes.length > 1 ? 'Zählerstände übermitteln' : 'Zählerstand übermitteln' }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <!-- Alert -->
      <v-row>
        <v-col cols="12" md="8" lg="6">
          <v-alert
            v-show="showAlert"
            class="mt-2"
            :color="alertType == 'error' ? 'error' : 'accent'"
            dense
            :type="alertType"
            v-html="alertMessage">
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-else class="black--text">
      <h2 class="mb-8 font-headline">Leider gab es ein Problem :(</h2>

      <p>
        Beim Laden Deiner alten Zählerstände gab es ein Problem. Tritt das Problem weiter auf, wende
        dich bitte an den Kundenservice.
      </p>
      <p>Dein VeganStrom Team</p>
      <v-btn
        width="300px"
        height="40px"
        color="error"
        class="mr-4 mb-1 text-none ft-700"
        @click="$router.push({ path: '/metering' })">
        Zurück zu den Zählerständen
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { postMeterReading, getObisCodes } from '../../api/api'
import { computeHighNumberHint } from '../../api/util'
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      validateButtonDisabled: false,
      meter_readings: [],
      name: '',
      email: '',
      meter_reading_date: '',
      meter_number: '',
      meter_number_new: '',
      has_meter_number_changed: false,
      change_meter_number: false,
      fieldRules: [v => !!v || this.$vuetify.lang.t('$vuetify.widget_meter_upload.text_required')],
      showAlert: false,
      alertType: 'success',
      alertMessage: this.$vuetify.lang.t('$vuetify.widget_meter_upload.alert_success'),
      timeleft: 5,
      downloadTimer: null,
    }
  },
  computed: {
    ...mapState({
      contract_selected: state => state.contract_selected,
      customer_selected: state => state.customer_selected,
      obis_codes: state => state.obis_codes,
      meter_readings_by_contract: state => state.meter_readings_by_contract,
      error: state => state.error,
    }),
    ...mapGetters({
      active_anticipated_payment: 'active_anticipated_payment',
    }),
  },
  watch: {
    contract_selected() {
      this.setupVariables()
    },
  },
  mounted() {
    this.setupVariables()
  },
  methods: {
    getHighNumberHint(meter_reading) {
      return computeHighNumberHint(
        meter_reading.meter_reading,
        meter_reading.last_meter_reading,
        meter_reading.last_meter_reading_date,
        meter_reading.high_consumption_factor,
        this.active_anticipated_payment?.consumption,
        this.meter_reading_date
      )
    },
    getNumberRules(meter_reading) {
      return [
        v => !!v || this.$vuetify.lang.t('$vuetify.widget_meter_upload.text_required'),
        v => !/[.,]/.test(v) || 'Bitte ohne Nachkommastellen eingeben',
        v => /^\d+$/.test(v) || 'Bitte nur Ziffern eingeben',
        v =>
          !meter_reading.last_meter_reading ||
          this.has_meter_number_changed ||
          Number(v) >= Number(meter_reading.last_meter_reading) ||
          'Der Zählerstand darf nicht kleiner sein als der letzte Zählerstand',
      ]
    },
    saveNewMeter() {
      this.change_meter_number = false
      this.has_meter_number_changed = this.meter_number != this.meter_number_new
    },
    validate() {
      if (this.$refs.form.validate()) {
        // Create payload
        let payload = {
          name: this.name,
          email: this.email,
          has_meter_number_changed: this.has_meter_number_changed,
          meter_number: this.contract_selected.meter_number,
          meter_number_new: this.has_meter_number_changed ? this.meter_number_new : undefined,
          meter_reading_date: new Date(this.meter_reading_date).toISOString().split('T')[0],
          meter_readings: this.meter_readings,
          contract_number: this.contract_selected.contract_number,
          contract_id: this.contract_selected.id,
        }
        this.validateButtonDisabled = true
        // Call API
        postMeterReading(payload).then(res => {
          // Show corresponding Alert
          if (res) {
            this.showAlert = true
            this.alertType = 'success'
            this.alertMessage = this.subject = this.$vuetify.lang.t(
              '$vuetify.widget_meter_upload.alert_success'
            )
            this.countDown()
          } else {
            this.showAlert = true
            this.alertType = 'error'
            this.alertMessage = this.subject = this.$vuetify.lang.t(
              '$vuetify.widget_meter_upload.alert_error'
            )
            this.validateButtonDisabled = false
          }
        })
      }
    },
    setupVariables() {
      // Set initial data
      this.name = this.customer_selected.first_name
      this.email = this.customer_selected.email
      this.meter_number = this.contract_selected.meter_number
      this.meter_number_new = this.contract_selected.meter_number
      getObisCodes().then(() => {
        this.meter_readings = []
        for (let index = 0; index < this.obis_codes.length; index++) {
          const obis_code = this.obis_codes[index]
          this.meter_readings.push({
            meter_reading: '',
            obis_code: obis_code.obis_code,
            last_meter_reading: obis_code.last_meter_reading,
            last_meter_reading_date: obis_code.last_meter_reading_date,
            high_consumption_factor: obis_code.high_consumption_factor,
          })
        }
      })
    },
    countDown() {
      let that = this
      this.showAlert = true
      this.downloadTimer = setInterval(function () {
        if (that.timeleft <= 0) {
          clearInterval(that.downloadTimer)
          this.validateButtonDisabled = false
          that.$router.push({ path: '/metering' })
        } else {
          that.alertMessage =
            that.$vuetify.lang.t('$vuetify.widget_meter_upload.alert_success') +
            '<br>Zurück zu den Zählerständen in ' +
            that.timeleft +
            '...'
        }
        that.timeleft -= 1
      }, 1000)
    },
    changeMeterNumber() {
      this.change_meter_number = true
      this.$gtag?.event('change_meter_number_clicked')
    },
  },
}
</script>

<style scoped>
.hidden-sm-and-down .v-icon {
  color: white !important;
}
</style>
<style>
.red-hint .v-messages:not(.error--text) .v-messages__message {
  color: red !important;
}
</style>
